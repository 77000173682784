<script lang="ts" setup>
	useTitle('Support | FIVB VIS WebManager');
</script>

<template>
	<DynamicLayout title="Support">
		<div class="space-y-8">
			<Panel title="International Transfers of Players">
				<p class="mb-4">
					If you have questions related to the international transfer procedure, please refer to the
					<a
						class="text-blue-500 hover:underline"
						href="https://www.fivb.com/-/media/2022/coorporate/volleyball/regulations%20and%20forms/faq_international_transfers_of_players_2022.pdf"
						>FAQ</a
					>
					that answers the most common queries. This document supplements the “ELECTRONIC INTERNATIONAL TRANSFER
					PROCEDURE MANUAL SEASON 2022-2023” available
					<a
						class="text-blue-500 hover:underline"
						href="https://www.fivb.com/-/media/2022/coorporate/volleyball/regulations%20and%20forms/fivb%20electronic%20international%20transfer%20procedure%20manual%202021-2022.pdf"
						>here</a
					>.
				</p>

				<p>
					If you still have a question regarding the transfer procedure, please get in touch with the transfer
					department.
				</p>

				<span class="mt-8 inline-flex rounded-md shadow-sm">
					<a
						class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 ring-blue-500 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none focus:ring active:bg-gray-50 active:text-gray-800"
						href="mailto:transfer@fivb.com"
					>
						Contact Transfer Department
					</a>
				</span>
			</Panel>
			<Panel title="Technical Difficulties">
				<p>If you encounter any difficulties using this application, please get in touch with the support.</p>

				<span class="mt-8 inline-flex rounded-md shadow-sm">
					<a
						class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 ring-blue-500 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none focus:ring active:bg-gray-50 active:text-gray-800"
						href="mailto:vis.support@fivb.com"
					>
						Contact Support
					</a>
				</span>
			</Panel>
		</div>
	</DynamicLayout>
</template>
