interface CustomSearchParams {
	gridState: any;
	fields: string[];
	updateCacheState?: () => void;
}

export function useCustomSearch(params: CustomSearchParams) {
	const { gridState, fields, updateCacheState } = params;

	const searchTerm = ref('');
	watchDebounced(searchTerm, () => search(), { debounce: 300 });

	function search() {
		if (!gridState.filter?.filters) {
			gridState.filter = {
				filters: [],
				logic: 'and',
			};
		}

		// @ts-expect-error Custom field
		gridState.filter.filters = gridState.filter.filters.filter((f) => f?.id !== 'custom');

		if (searchTerm.value) {
			gridState.filter?.filters.push({
				id: 'custom',
				logic: 'or',
				filters: fields.map((field) => {
					return { field, operator: 'contains', value: searchTerm.value.trim() };
				}),
			});

			gridState.skip = 0;
		}

		updateCacheState?.();
	}

	function clearFilter() {
		searchTerm.value = '';

		gridState.filter = {
			filters: [],
			logic: 'and',
		};

		updateCacheState?.();
	}

	return {
		searchTerm,
		search,
		clearFilter,
	};
}
