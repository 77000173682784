<script lang="ts" setup>
	defineProps<{
		navigation: {
			label: string;
			name: string;
			params?: Record<string, string>;
			disabled?: boolean;
		}[];
	}>();

	const currentRoute = useRoute();
	const router = useRouter();
	function navigate(event: InputEvent) {
		const target = event.target as HTMLInputElement;

		router.push(JSON.parse(target.value));
	}
</script>

<template>
	<!-- Desktop View -->
	<div class="hidden sm:block">
		<div class="relative z-0 flex divide-x divide-gray-200 rounded-lg shadow">
			<template v-for="(route, index) in navigation" :key="route.name">
				<RouterLink
					v-if="!route.disabled"
					v-slot="{ isExactActive }"
					class="group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10"
					:class="{
						'rounded-l-lg': index === 0,
						'rounded-r-lg': index === navigation.length - 1,
					}"
					:to="route"
				>
					<span>{{ route.label }}</span>
					<span v-if="isExactActive" aria-hidden="true" class="absolute inset-x-0 bottom-0 h-0.5 bg-blue-500"></span>
				</RouterLink>

				<div
					v-else
					class="group relative min-w-0 flex-1 overflow-hidden px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 cursor-not-allowed text-gray-500 bg-gray-50"
				>
					{{ route.label }}
				</div>
			</template>
		</div>
	</div>

	<!-- Mobile View -->
	<div class="sm:hidden">
		<label for="tabs" class="sr-only">Select a tab</label>
		<select
			id="tabs"
			name="tabs"
			class="form-input block w-full flex-1 rounded-md transition duration-150 ease-in-out focus:ring-blue-500 sm:text-sm sm:leading-4"
			@input="navigate"
		>
			<option
				v-for="route in navigation"
				:key="route"
				:value="JSON.stringify(route)"
				:selected="currentRoute.name === route.name"
				:disabled="route.disabled"
			>
				{{ route.label }}
			</option>
		</select>
	</div>
</template>
