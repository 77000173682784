import TheShell from '~/ui/Layout/TheShell.vue';
import type { RouteRecordRaw } from 'vue-router';

export const routes = [
	{
		path: '/beach/tournaments',
		component: () => import('~/app/beach/views/beach-tournament-list-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/beach/rankings',
		component: () => import('~/app/beach/views/beach-ranking-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/beach/tournaments/:id(\\d+)',
		component: () => import('~/app/beach/views/beach-tournament-view.vue'),
		children: [
			{
				path: '',
				name: 'beach.tournaments.view',
				component: () => import('~/app/beach/components/beach-tournament-information-panel.vue'),
			},
			{
				path: 'matches',
				name: 'beach.tournaments.view.matches',
				component: () => import('~/app/beach/components/beach-tournament-matches-panel.vue'),
			},
			{
				path: 'teams',
				name: 'beach.tournaments.view.teams',
				component: () => import('~/app/beach/components/beach-tournament-teams-panel.vue'),
			},
		],
		meta: {
			layout: TheShell,
		},
	},
] satisfies Array<RouteRecordRaw>;
