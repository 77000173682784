import TheShell from '~/ui/Layout/TheShell.vue';
import type { RouteRecordRaw } from 'vue-router';

export const routes = [
	{
		path: '/referees',
		component: () => import('~/app/person/views/referee-list-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/referees/:id(\\d+)',
		component: () => import('~/app/person/views/referee-view.vue'),
		children: [
			{
				path: '',
				name: 'referees.view',
				component: () => import('~/app/person/components/referees/referee-profile-panel.vue'),
			},
			{
				path: 'career',
				name: 'referees.view.career',
				component: () => import('~/app/person/components/referees/referee-career-tab.vue'),
			},
			{
				path: 'bank-account',
				name: 'referees.view.bank-account',
				component: () => import('~/app/person/components/referees/referee-bank-account-panel.vue'),
			},
			{
				path: 'availability',
				name: 'referees.view.availability',
				component: () => import('~/app/person/components/referees/referee-availability-tab.vue'),
			},
			{
				path: 'physical',
				name: 'referees.view.physical',
				component: () => import('~/app/person/components/referees/referee-physical-tab.vue'),
			},
			{
				path: 'nominations',
				name: 'referees.view.nominations',
				component: () => import('~/app/person/components/referees/referee-nominations-panel.vue'),
			},
			{
				path: 'documents',
				name: 'referees.view.documents',
				component: () => import('~/app/person/components/referees/referee-documents-panel.vue'),
			},
		],
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/officials',
		component: () => import('~/app/person/views/official-list-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/officials/:id(\\d+)',
		component: () => import('~/app/person/views/official-view.vue'),
		children: [
			{
				path: '',
				name: 'officials.view',
				component: () => import('~/app/person/components/officials/official-profile-panel.vue'),
			},
			{
				path: 'bank-account',
				name: 'officials.view.bank-account',
				component: () => import('~/app/person/components/officials/official-bank-account-panel.vue'),
			},
			{
				path: 'availability',
				name: 'officials.view.availability',
				component: () => import('~/app/person/components/officials/official-availability-tab.vue'),
			},
			{
				path: 'physical',
				name: 'officials.view.physical',
				component: () => import('~/app/person/components/officials/official-physical-tab.vue'),
			},
			{
				path: 'nominations',
				name: 'officials.view.nominations',
				component: () => import('~/app/person/components/officials/official-nominations-tab.vue'),
			},
			{
				path: 'documents',
				name: 'officials.view.documents',
				component: () => import('~/app/person/components/officials/official-documents-panel.vue'),
			},
		],
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/coaches',
		component: () => import('~/app/person/views/coach-list-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/coaches/:id(\\d+)',
		component: () => import('~/app/person/views/coach-view.vue'),
		children: [
			{
				path: '',
				name: 'coaches.view',
				component: () => import('~/app/person/components/coaches/coach-profile-panel.vue'),
			},
			{
				path: 'documents',
				name: 'coaches.view.documents',
				component: () => import('~/app/person/components/coaches/coach-documents-panel.vue'),
			},
		],
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/medical-staff',
		component: () => import('~/app/person/views/medical-staff-list-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/medical-staff/:id(\\d+)',
		component: () => import('~/app/person/views/medical-staff-view.vue'),
		children: [
			{
				path: '',
				name: 'medical-staff.view',
				component: () => import('~/app/person/components/medical-staff/medical-staff-profile-panel.vue'),
			},
			{
				path: 'documents',
				name: 'medical-staff.view.documents',
				component: () => import('~/app/person/components/medical-staff/medical-staff-documents-panel.vue'),
			},
		],
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/media-staff',
		component: () => import('~/app/person/views/media-staff-list-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/media-staff/:id(\\d+)',
		component: () => import('~/app/person/views/media-staff-view.vue'),
		children: [
			{
				path: '',
				name: 'media-staff.view',
				component: () => import('~/app/person/components/media-staff/media-staff-profile-panel.vue'),
			},
			{
				path: 'documents',
				name: 'media-staff.view.documents',
				component: () => import('~/app/person/components/media-staff/media-staff-documents-panel.vue'),
			},
		],
		meta: {
			layout: TheShell,
		},
	},
] satisfies RouteRecordRaw[];
