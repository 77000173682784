<script lang="ts" setup>
	import { http } from '~/infrastructure/http';

	const props = withDefaults(
		defineProps<{
			imageId: number | null;
			version?: number;
			format?: 'square';
			size?: [number, number];
		}>(),
		{
			version: 1,
			format: 'square',
			size: () => [200, 200],
		}
	);

	const imageLink = computed(() =>
		http.compute(
			`/images/${props.imageId}?Height=${props.size[0]}&Width=${props.size[1]}&Stretch=UniformToFill&Version=${props.version}`
		)
	);
</script>

<template>
	<img v-if="imageId" v-bind="$attrs" :src="imageLink" alt="" />
	<span v-else v-bind="$attrs" class="inline-block overflow-hidden bg-white">
		<svg class="max-h-[200px] max-w-[200px] text-gray-300" fill="currentColor" viewBox="0 0 24 24">
			<path
				d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
			/>
		</svg>
	</span>
</template>
