import { queryKeyFactory } from '~/app/auth/queries/query-key-factory';
import { queryClient } from '~/infrastructure/query-client';

export type AuthorizationActions = 'view' | 'change' | 'insert';

export class Security<T> {
	private item?: T;
	private dataType: string;
	private access: any;

	constructor(dataType: string, item?: T) {
		this.item = item;
		this.dataType = dataType;

		const accesses = queryClient.getQueryData<any[]>(queryKeyFactory.userAccesses());

		if (!accesses) {
			throw new Error('No accesses found');
		}

		const access = accesses.find((a) => a.dataType === this.dataType);

		if (!access) {
			throw new Error(`No access found for the type ${dataType}`);
		}

		this.access = access;

		// Compute custom permissions
		if (this.item) {
			const canModify = this.access.authorizations.change?.some((permission) => {
				if (!permission.conditions) {
					return true;
				}

				// @ts-expect-error Verify typing
				return permission.conditions.every((condition) => this.item[condition.propertyName] == condition.value);
			});

			if (canModify) {
				this.access.permissions.push({ name: '$canModify' });
			} else {
				this.access.permissions = this.access.permissions.filter((p) => p.name !== '$canModify');
			}
		} else {
			const canModify = this.access.authorizations.change?.some((permission) => {
				return !permission.conditions;
			});

			if (canModify) {
				this.access.permissions.push({ name: '$canModify' });
			} else {
				this.access.permissions = this.access.permissions.filter((p) => p.name !== '$canModify');
			}
		}

		const canInsert = this.access.authorizations.insert && this.access.authorizations.insert.length > 0;

		if (canInsert) {
			this.access.permissions.push({ name: '$canInsert' });
		} else {
			this.access.permissions = this.access.permissions.filter((p) => p.name !== '$canInsert');
		}
	}

	static using(dataType: string): Security<undefined>;
	static using<T>(item: T, dataType: string): Security<T>;
	static using<T>(item: T | string, dataType?: string) {
		if (dataType) {
			return new Security(dataType, item);
		}

		return new Security(item as string);
	}

	public allowedValues(action: AuthorizationActions, fieldName: string) {
		const authorizations = this.access.authorizations[action];

		if (!authorizations) {
			return [];
		}

		const field = authorizations.find((f) => f.name === fieldName);

		if (!field) {
			return [];
		}

		return field.values;
	}

	public isGranted(permissionName: string): boolean;
	public isGranted(action: AuthorizationActions, fieldName: string): boolean;
	public isGranted(action: AuthorizationActions, fieldName?: string): boolean {
		if (!fieldName) {
			return this.verify(action);
		}

		return this.verify(action, fieldName);
	}

	private verify(permissionName: string): boolean;
	private verify(action: AuthorizationActions, related: string): boolean;
	private verify(action: AuthorizationActions | string, related?: string): boolean {
		if (!this.access) {
			return false;
		}

		if (!this.isAnAction(action)) {
			return this.verifyPermission(action);
		}

		const permissions = this.access.authorizations[action]?.filter((p) => p.name === related);

		if (!permissions) {
			return false;
		}

		return permissions.some((permission) => {
			if (!permission.conditions) {
				return true;
			}

			if (!this.item) {
				return false;
			}

			// @ts-expect-error Verify typing
			return permission.conditions.every((condition) => this.item[condition.propertyName] == condition.value);
		});
	}

	private isAnAction(action: AuthorizationActions | string): action is AuthorizationActions {
		return ['change', 'insert', 'view'].includes(action);
	}

	private verifyPermission(permissionName: string): boolean {
		const permissions = this.access!.permissions.filter((permission) => permission.name === permissionName);

		if (!permissions) {
			return false;
		}

		return permissions.some((permission) => {
			if (!permission.conditions) {
				return true;
			}

			// @ts-expect-error Verify typing
			return permission.conditions.every((condition) => this.item[condition.propertyName] == condition.value);
		});
	}
}
