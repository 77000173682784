import TheShell from '~/ui/Layout/TheShell.vue';
import type { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		component: () => import('~/app/core/views/dashboard-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/support',
		component: () => import('~/app/core/views/support-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/404',
		component: () => import('~/app/core/views/not-found-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/:pathMatch(.*)*',
		component: () => import('~/app/core/views/not-found-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
];
