import { Federation } from '@/domain/bodies/federation';

export const PlayerProperty = {
	// Accounting number.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~XAttr_AccountNo.html
	AccountNo: 'AccountNo',

	// Value that indicates whether the player is active for beach volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~XAttr_ActiveBeach.html
	ActiveBeach: 'ActiveBeach',

	// Value that indicates whether the player is active for snow volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~XAttr_ActiveSnow.html
	ActiveSnow: 'ActiveSnow',

	// Value that indicates whether the player is active for volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~XAttr_ActiveVolley.html
	ActiveVolley: 'ActiveVolley',

	// First line of the address.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~XAttr_Address1.html
	Address1: 'Address1',

	// Second line of the address
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~XAttr_Address2.html
	Address2: 'Address2',

	// Date when the anti-doping course has been completed by the player.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~XAttr_AntiDopingCourseCompleteDate.html
	AntiDopingCourseCompleteDate: 'AntiDopingCourseCompleteDate',

	// Date of signature of the 1st beach volleyball agreement
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~XAttr_BeachAgreementDate.html
	BeachAgreementDate: 'BeachAgreementDate',

	// Date of signature of the 2nd beach volleyball agreement
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~XAttr_BeachAgreementDate2.html
	BeachAgreementDate2: 'BeachAgreementDate2',

	// Date of signature of the confederation agreement for beach volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~BeachAgreementDateCF.html
	BeachAgreementDateCF: 'BeachAgreementDateCF',

	// Validity date for beach volleyball agreement.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~BeachAgreementValid.html
	BeachAgreementValid: 'BeachAgreementValid',

	// Name of current team for beach volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~BeachCurrentTeam.html
	BeachCurrentTeam: 'BeachCurrentTeam',

	// Name of the first event played for beach volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~BeachFirstEvent.html
	BeachFirstEvent: 'BeachFirstEvent',

	// Highest block for beach volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~BeachHighestBlock.html
	BeachHighBlock: 'BeachHighBlock',

	// Highest jump for beach volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~BeachHighestJump.html
	BeachHighJump: 'BeachHighJump',

	// Highest spike for beach volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~BeachHighestSpike.html
	BeachHighSpike: 'BeachHighSpike',

	// Highest stand for beach volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~BeachHighestStand.html
	BeachHighStand: 'BeachHighStand',

	// Number of Olympic Games selections for beach volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~BeachNbSelOG.html
	BeachNbSelOG: 'BeachNbSelOG',

	// Number of other international selections for beach volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~BeachNbSelOther.html
	BeachNbSelOther: 'BeachNbSelOther',

	// Number of World Championships selections for beach volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~BeachNbSelWC.html
	BeachNbSelWC: 'BeachNbSelWC',

	// Court position for beach volleyball.
	// @see https://www.fivb.org/VisSDK/Fivb.Vis.DataWPF/Fivb.Vis.DataWPF~Fivb.Vis.Person.Player~BeachPosition.html
	BeachPosition: 'BeachPosition',

	// Date when the player was retired from beach volleyball.
	BeachRetiredDate: 'BeachRetiredDate',

	// Value that indicates whether the player is suspended for beach volleyball.
	BeachSuspended: 'BeachSuspended',

	// Name of the team in which the player begins playing for beach volleyball.
	BeachTeamBegin: 'BeachTeamBegin',

	// Place where the player begins playing for beach volleyball.
	BeachWhereBegin: 'BeachWhereBegin',

	// Year when the player begins playing for beach volleyball.
	BeachYearBegin: 'BeachYearBegin',

	// ISO 3166-1-alpha-2 country code of the birth place.
	BirthCountryCode: 'BirthCountryCode',

	// Name of the country of the player's birth place.
	BirthCountryName: 'BirthCountryName',

	// Birth date of the player.
	Birthdate: 'Birthdate',

	// Name of the city of the player's birth place.
	BirthPlace: 'BirthPlace',

	// Blood group of the player.
	BloodGroup: 'BloodGroup',

	// Value that indicates whether the player can be deleted.
	CanBeDeleted: 'CanBeDeleted',

	// Name of the children.
	Children: 'Children',

	// The city part of the address.
	City: 'City',

	// The code of the confederation.
	ConfederationCode: 'ConfederationCode',

	// The ISO 3166-1-alpha-2 country code for the address.
	CountryCode: 'CountryCode',

	// The UTC date and time when the player was created.
	CreatedAt: 'CreatedAt',

	// The number of the user who has created the player.
	CreatedBy: 'CreatedBy',

	// The date of the regulation acceptance.
	DateCommit: 'DateCommit',

	// The date of the health certificate.
	DateHealthCert: 'DateHealthCert',

	// The date of the health certificate for the confederation.
	DateHealthCertificateConfederation: 'DateHealthCertificateConfederation',

	// The date of the FIVB identity card.
	DateIdCard: 'DateIdCard',

	// The date of the last maternity.
	DateLastMaternity: 'DateLastMaternity',

	// The date of the passport.
	DatePassport: 'DatePassport',

	// The date of the payment form.
	DatePaymentForm: 'DatePaymentForm',

	// The validity date for the femininity certificate.
	DateValidFeminityCert: 'DateValidFeminityCert',

	// The validity date for the FIVB identity card.
	DateValidIdCard: 'DateValidIdCard',

	// The validity date for the passport.
	DateValidPassport: 'DateValidPassport',

	// The education information.
	Education: 'Education',

	// The date and time when the player eligibility was verified.
	EligibilityVerifiedAt: 'EligibilityVerifiedAt',

	// The number of the user has verified the player eligibility.
	EligibilityVerifiedBy: 'EligibilityVerifiedBy',

	// The e-mail address.
	EMailAddress: 'EMailAddress',

	// The URL of the Facebook page.
	FacebookUrl: 'FacebookUrl',

	// The sports played by other family members.
	FamilySports: 'FamilySports',

	// Information about the favorite athlete.
	FavoriteAthlete: 'FavoriteAthlete',

	// Information about the favorite book.
	FavoriteBook: 'FavoriteBook',

	// The city where the player prefers to play.
	FavoriteCityToPlay: 'FavoriteCityToPlay',

	// Information about the favorite food.
	FavoriteFood: 'FavoriteFood',

	// Information about the favorite movie.
	FavoriteMovie: 'FavoriteMovie',

	// The favorite music.
	FavoriteMusic: 'FavoriteMusic',

	// Information about the favorite professional team.
	FavoriteProTeam: 'FavoriteProTeam',

	// The code of the federation.
	FederationCode: 'FederationCode',

	// The first name.
	FirstName: 'FirstName',

	// The gender.
	Gender: 'Gender',

	// The handedness.
	Handedness: 'Handedness',

	// The height.
	Height: 'Height',

	// The hobbies.
	Hobbies: 'Hobbies',

	// The URL of the Instagram account.
	InstagramUrl: 'InstagramUrl',

	// A value that indicates whether the player is active.
	IsActive: 'IsActive',

	// A list of the spoken languages.
	Languages: 'Languages',

	// The date and time of the last change.
	LastChangeDT: 'LastChangeDT',

	// The name of the user who makes the last change.
	LastChangeUsername: 'LastChangeUsername',

	// The last name.
	LastName: 'LastName',

	// The marital status.
	MaritalStatus: 'MaritalStatus',

	// The measurement.
	Measurement: 'Measurement',

	// The name of the brothers.
	NameBrothers: 'NameBrothers',

	// The name of the father.
	NameFather: 'NameFather',

	// The name of the mother.
	NameMother: 'NameMother',

	// The name of the sisters.
	NameSisters: 'NameSisters',

	// The name of the spouse.
	NameSpouse: 'NameSpouse',

	// The name of the user related to the player.
	NameUser: 'NameUser',

	// The ISO 3166-1-alpha-2 country code for the nationality.
	NationalityCode: 'NationalityCode',

	// The number of views of the biography.
	NbViewBio: 'NbViewBio',

	// ID
	No: 'No',

	// The number of the bank account.
	NoBankAccount: 'NoBankAccount',

	// The CEV number.
	NoCev: 'NoCev',

	// The number of the document that contains the beach volleyball agreement.
	NoDocumentBeachAgreement: 'NoDocumentBeachAgreement',

	// The number of the document that contains the commitment and health certificate for a confederation.
	NoDocumentCommitmentAndHealthCertCF: 'NoDocumentCommitmentAndHealthCertCF',

	// The number of the document that contains the health certificate.
	NoDocumentHealthCertificate: 'NoDocumentHealthCertificate',

	// The number of the document that contains the passport.
	NoDocumentPassport: 'NoDocumentPassport',

	// The home fax number.
	NoFaxHome: 'NoFaxHome',

	// The office fax number.
	NoFaxOffice: 'NoFaxOffice',

	// The number of the federation license.
	NoFedLicense: 'NoFedLicense',

	// The number of the femininity certificate.
	NoFeminityCert: 'NoFeminityCert',

	// The number of the FIVB identity card.
	NoId: 'NoId',

	// The mobile phone number.
	NoMobile: 'NoMobile',

	// The number of the passport.
	NoPassport: 'NoPassport',

	// The home phone number.
	NoPhoneHome: 'NoPhoneHome',

	// The office phone number.
	NoPhoneOffice: 'NoPhoneOffice',

	// The number of the official portrait photo.
	NoPhoto: 'NoPhoto',

	// The number of the user related to the player.
	NoUser: 'NoUser',

	// The occupation of the spouse.
	OccupationSpouse: 'OccupationSpouse',

	// A value that indicates whether the official questionnaire has been answered.
	OfficialQuestDone: 'OfficialQuestDone',

	// What the player is known for on court.
	OnCourtKnownFor: 'OnCourtKnownFor',

	// The pants size.
	PantsSize: 'PantsSize',

	// The person who is most admired.
	PersonMostAdmire: 'PersonMostAdmire',

	// The name and kind of the pets.
	Pets: 'Pets',

	// The player status.
	PlayerStatus: 'PlayerStatus',

	// The type of player.
	PlayerType: 'PlayerType',

	// A value that indicates whether the player plays beach volleyball.
	PlaysBeach: 'PlaysBeach',

	// A value that indicates whether the player plays snow volleyball.
	PlaysSnow: 'PlaysSnow',

	// A value that indicates whether the player plays volleyball.
	PlaysVolley: 'PlaysVolley',

	// The popular name.
	PopularName: 'PopularName',

	// The previous names.
	PreviousNames: 'PreviousNames',

	// The profession.
	Profession: 'Profession',

	// The profile.
	Profile: 'Profile',

	// The rhesus factor.
	RhesusFactor: 'RhesusFactor',

	// The shirt size.
	ShirtSize: 'ShirtSize',

	// What the Snapchat username.
	SnapchatUsername: 'SnapchatUsername',

	// The sponsors.
	Sponsors: 'Sponsors',

	// How the team mates call the player.
	TeamMatesCallMe: 'TeamMatesCallMe',

	// The team name.
	TeamName: 'TeamName',

	// The title.
	Title: 'Title',

	// The URL of the Twitter account.
	TwitterUrl: 'TwitterUrl',

	// The date and time when the player was last verified.
	VerifiedAt: 'VerifiedAt',

	Version: 'Version',

	// The name of the club where the player begins playing volleyball.
	VolleyClubBegin: 'VolleyClubBegin',

	// The name of the current volleyball team.
	VolleyCurrentTeam: 'VolleyCurrentTeam',

	// The name of the first international volleyball event played.
	VolleyFirstIntlEvent: 'VolleyFirstIntlEvent',

	// The highest block for volleyball.
	VolleyHighBlock: 'VolleyHighBlock',

	// The highest jump for volleyball.
	VolleyHighJump: 'VolleyHighJump',

	// The highest spike for volleyball.
	VolleyHighSpike: 'VolleyHighSpike',

	// The highest stand for volleyball.
	VolleyHighStand: 'VolleyHighStand',

	// The number of Olympic Games selections for volleyball.
	VolleyNbSelOG: 'VolleyNbSelOG',

	// The number of other international selections for volleyball.
	VolleyNbSelOther: 'VolleyNbSelOther',

	// The number of World Championship selections for volleyball.
	VolleyNbSelWC: 'VolleyNbSelWC',

	// The court position for volleyball.
	VolleyPosition: 'VolleyPosition',

	// A value that indicates whether the player is suspended for volleyball.
	VolleySuspended: 'VolleySuspended',

	// A value that indicates whether a volleyball transfer must be made under FIVB umbrella.
	VolleyTransferFivbUmbrella: 'VolleyTransferFivbUmbrella',

	// The place where the player has his/her first international volleyball selection.
	VolleyWhere1stIntlSel: 'VolleyWhere1stIntlSel',

	// The place where the player begins playing volleyball.
	VolleyWhereBegin: 'VolleyWhereBegin',

	// The place where is the current volleyball team.
	VolleyWhereCurTeam: 'VolleyWhereCurTeam',

	// The year of the first international volleyball selection.
	VolleyYear1stIntlSel: 'VolleyYear1stIntlSel',

	// The year when the player begins playing volleyball.
	VolleyYearBegin: 'VolleyYearBegin',

	// The website.
	WebSite: 'WebSite',

	// The weight.
	Weight: 'Weight',

	// A text that explains why volleyball is the favorite sport of the player.
	WhyVolleyFavoriteSport: 'WhyVolleyFavoriteSport',

	// The zip code part of the address
	ZipCode: 'ZipCode',
} as const;

export const PlayerRelation = {
	Federation: Federation,
} as const;

export const Player = {
	properties: PlayerProperty,
	relations: PlayerRelation,
} as const;

export const playerListProperty = [
	PlayerProperty.ActiveBeach,
	PlayerProperty.ActiveSnow,
	PlayerProperty.ActiveVolley,
	PlayerProperty.Birthdate,
	PlayerProperty.ConfederationCode,
	PlayerProperty.EMailAddress,
	PlayerProperty.FirstName,
	PlayerProperty.Gender,
	PlayerProperty.LastName,
	PlayerProperty.NameUser,
	PlayerProperty.NationalityCode,
	PlayerProperty.No,
	PlayerProperty.NoCev,
	PlayerProperty.TeamName,
	PlayerProperty.Version,
];

export const playerDetailProperty = [
	PlayerProperty.ActiveBeach,
	PlayerProperty.ActiveSnow,
	PlayerProperty.ActiveVolley,
	PlayerProperty.Address1,
	PlayerProperty.Address2,
	PlayerProperty.BirthCountryCode,
	PlayerProperty.BirthPlace,
	PlayerProperty.Birthdate,
	PlayerProperty.BloodGroup,
	PlayerProperty.City,
	PlayerProperty.CountryCode,
	PlayerProperty.DateValidPassport,
	PlayerProperty.EMailAddress,
	PlayerProperty.EligibilityVerifiedAt,
	PlayerProperty.EligibilityVerifiedBy,
	PlayerProperty.FederationCode,
	PlayerProperty.FirstName,
	PlayerProperty.Gender,
	PlayerProperty.Height,
	PlayerProperty.LastName,
	PlayerProperty.NationalityCode,
	PlayerProperty.No,
	PlayerProperty.NoCev,
	PlayerProperty.NoPassport,
	PlayerProperty.NoDocumentPassport,
	PlayerProperty.NoFaxHome,
	PlayerProperty.NoFaxOffice,
	PlayerProperty.NoPhoneHome,
	PlayerProperty.NoPhoneOffice,
	PlayerProperty.NoUser,
	'NoPerson',
	PlayerProperty.PlaysBeach,
	PlayerProperty.PlaysSnow,
	PlayerProperty.PlaysVolley,
	PlayerProperty.TeamName,
	PlayerProperty.Version,
	PlayerProperty.Weight,
	PlayerProperty.ZipCode,
];
