import TheShell from '~/ui/Layout/TheShell.vue';
import type { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
	{
		path: '/admin/users',
		component: () => import('~/app/admin/views/user-list-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/admin/users/online',
		component: () => import('~/app/admin/views/online-user-list-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/admin/documents/consents/validation',
		component: () => import('~/app/admin/views/consent-validation-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/admin/documents/beach/validation',
		component: () => import('~/app/admin/views/beach-document-validation-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
];
