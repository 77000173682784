import type { Ref } from 'vue';
import type { FederationChangeFilters } from '~/app/players/repositories/fetch-federation-change-list';

export const queryKeyFactory = {
	root: ['players'] as const,
	lists: () => [...queryKeyFactory.root, 'list'] as const,
	list: (filters: Ref<Record<string, unknown>>) => [...queryKeyFactory.lists(), filters] as const,
	details: () => [...queryKeyFactory.root, 'detail'] as const,
	detail: (id: Ref<number | null> | number) => [...queryKeyFactory.details(), id] as const,
};

export const federationChangeKeys = {
	root: ['federationChanges'] as const,
	lists: () => [...federationChangeKeys.root, 'list'] as const,
	list: (filters: FederationChangeFilters) => [...federationChangeKeys.lists(), filters] as const,
};

export const queryKeys = queryKeyFactory;
export const playerKeys = {
	root: ['players', 'v2'] as const,
	details: () => [...playerKeys.root, 'detail'] as const,
	detail: (no: Ref<number>) => [...playerKeys.details(), no] as const,
	list: (filters: Record<string, unknown>) => [...playerKeys.root, 'list', filters] as const,
};
