const codes = [
	{ fivb: 'VAR', iso2: '00', iso3: '' },
	{ fivb: 'ENG', iso2: '01', iso3: '' },
	{ fivb: 'NIR', iso2: '02', iso3: '' },
	{ fivb: 'SCO', iso2: '03', iso3: '' },
	{ fivb: 'WAL', iso2: '04', iso3: '' },
	{ fivb: 'SCD', iso2: '88', iso3: '' },
	{ fivb: '99', iso2: '99', iso3: '' },
	{ fivb: 'MAY', iso2: 'YT', iso3: 'MYT' },
	{ fivb: 'REU', iso2: 'RE', iso3: 'REU' },
	{ fivb: 'AND', iso2: 'AD', iso3: 'AND' },
	{ fivb: 'UAE', iso2: 'AE', iso3: 'ARE' },
	{ fivb: 'AFG', iso2: 'AF', iso3: 'AFG' },
	{ fivb: 'ANT', iso2: 'AG', iso3: 'ATG' },
	{ fivb: 'AGU', iso2: 'AI', iso3: 'AIA' },
	{ fivb: 'ALB', iso2: 'AL', iso3: 'ALB' },
	{ fivb: 'ARM', iso2: 'AM', iso3: 'ARM' },
	{ fivb: 'AHO', iso2: 'AN', iso3: 'ANT' },
	{ fivb: 'ANG', iso2: 'AO', iso3: 'AGO' },
	{ fivb: 'ARG', iso2: 'AR', iso3: 'ARG' },
	{ fivb: 'ASA', iso2: 'AS', iso3: 'ASM' },
	{ fivb: 'AUT', iso2: 'AT', iso3: 'AUT' },
	{ fivb: 'AUS', iso2: 'AU', iso3: 'AUS' },
	{ fivb: 'ARU', iso2: 'AW', iso3: 'ABW' },
	{ fivb: 'AZE', iso2: 'AZ', iso3: 'AZE' },
	{ fivb: 'BIH', iso2: 'BA', iso3: 'BIH' },
	{ fivb: 'BAR', iso2: 'BB', iso3: 'BRB' },
	{ fivb: 'BAN', iso2: 'BD', iso3: 'BGD' },
	{ fivb: 'BEL', iso2: 'BE', iso3: 'BEL' },
	{ fivb: 'BUR', iso2: 'BF', iso3: 'BFA' },
	{ fivb: 'BUL', iso2: 'BG', iso3: 'BGR' },
	{ fivb: 'BRN', iso2: 'BH', iso3: 'BHR' },
	{ fivb: 'BDI', iso2: 'BI', iso3: 'BDI' },
	{ fivb: 'BEN', iso2: 'BJ', iso3: 'BEN' },
	{ fivb: 'BER', iso2: 'BM', iso3: 'BMU' },
	{ fivb: 'BRU', iso2: 'BN', iso3: 'BRN' },
	{ fivb: 'BOL', iso2: 'BO', iso3: 'BOL' },
	{ fivb: 'BRA', iso2: 'BR', iso3: 'BRA' },
	{ fivb: 'BAH', iso2: 'BS', iso3: 'BHS' },
	{ fivb: 'BHU', iso2: 'BT', iso3: 'BTN' },
	{ fivb: 'BOT', iso2: 'BW', iso3: 'BWA' },
	{ fivb: 'BLR', iso2: 'BY', iso3: 'BLR' },
	{ fivb: 'BIZ', iso2: 'BZ', iso3: 'BLZ' },
	{ fivb: 'CAN', iso2: 'CA', iso3: 'CAN' },
	{ fivb: 'COD', iso2: 'CD', iso3: 'COD' },
	{ fivb: 'CAF', iso2: 'CF', iso3: 'CAF' },
	{ fivb: 'CGO', iso2: 'CG', iso3: 'COG' },
	{ fivb: 'SUI', iso2: 'CH', iso3: 'CHE' },
	{ fivb: 'CIV', iso2: 'CI', iso3: 'CIV' },
	{ fivb: 'COK', iso2: 'CK', iso3: 'COK' },
	{ fivb: 'CHI', iso2: 'CL', iso3: 'CHL' },
	{ fivb: 'CMR', iso2: 'CM', iso3: 'CMR' },
	{ fivb: 'CHN', iso2: 'CN', iso3: 'CHN' },
	{ fivb: 'COL', iso2: 'CO', iso3: 'COL' },
	{ fivb: 'CRC', iso2: 'CR', iso3: 'CRI' },
	{ fivb: 'SCG', iso2: 'CS', iso3: 'SCG' },
	{ fivb: 'CUB', iso2: 'CU', iso3: 'CUB' },
	{ fivb: 'CPV', iso2: 'CV', iso3: 'CPV' },
	{ fivb: 'BON', iso2: 'BQ' },
	{ fivb: 'CUR', iso2: 'CW' },
	{ fivb: 'SMA', iso2: 'MF' },
	{ fivb: 'SXM', iso2: 'SX' },
	{ fivb: 'TCI', iso2: 'TC' },
	{ fivb: 'CYP', iso2: 'CY', iso3: 'CYP' },
	{ fivb: 'CZE', iso2: 'CZ', iso3: 'CZE' },
	{ fivb: 'GER', iso2: 'DE', iso3: 'DEU' },
	{ fivb: 'DJI', iso2: 'DJ', iso3: 'DJI' },
	{ fivb: 'DEN', iso2: 'DK', iso3: 'DNK' },
	{ fivb: 'DMA', iso2: 'DM', iso3: 'DMA' },
	{ fivb: 'DOM', iso2: 'DO', iso3: 'DOM' },
	{ fivb: 'ALG', iso2: 'DZ', iso3: 'DZA' },
	{ fivb: 'ECU', iso2: 'EC', iso3: 'ECU' },
	{ fivb: 'EST', iso2: 'EE', iso3: 'EST' },
	{ fivb: 'EGY', iso2: 'EG', iso3: 'EGY' },
	{ fivb: 'ERT', iso2: 'ER', iso3: 'ERI' },
	{ fivb: 'ESP', iso2: 'ES', iso3: 'ESP' },
	{ fivb: 'ETH', iso2: 'ET', iso3: 'ETH' },
	{ fivb: 'FIN', iso2: 'FI', iso3: 'FIN' },
	{ fivb: 'FIJ', iso2: 'FJ', iso3: 'FJI' },
	{ fivb: 'FSM', iso2: 'FM', iso3: 'FSM' },
	{ fivb: 'FAR', iso2: 'FO', iso3: 'FRO' },
	{ fivb: 'FRA', iso2: 'FR', iso3: 'FRA' },
	{ fivb: 'GAB', iso2: 'GA', iso3: 'GAB' },
	{ fivb: 'GBR', iso2: 'GB', iso3: 'GBR' },
	{ fivb: 'GRN', iso2: 'GD', iso3: 'GRD' },
	{ fivb: 'GEO', iso2: 'GE', iso3: 'GEO' },
	{ fivb: 'FGU', iso2: 'GF', iso3: 'GUF' },
	{ fivb: 'GHA', iso2: 'GH', iso3: 'GHA' },
	{ fivb: 'GIB', iso2: 'GI', iso3: 'GIB' },
	{ fivb: 'GRL', iso2: 'GL', iso3: 'GRL' },
	{ fivb: 'GAM', iso2: 'GM', iso3: 'GMB' },
	{ fivb: 'GUI', iso2: 'GN', iso3: 'GIN' },
	{ fivb: 'GDP', iso2: 'GP', iso3: 'GLP' },
	{ fivb: 'GEQ', iso2: 'GQ', iso3: 'GNQ' },
	{ fivb: 'GRE', iso2: 'GR', iso3: 'GRC' },
	{ fivb: 'GUA', iso2: 'GT', iso3: 'GTM' },
	{ fivb: 'GUM', iso2: 'GU', iso3: 'GUM' },
	{ fivb: 'GBS', iso2: 'GW', iso3: 'GNB' },
	{ fivb: 'GUY', iso2: 'GY', iso3: 'GUY' },
	{ fivb: 'HKG', iso2: 'HK', iso3: 'HKG' },
	{ fivb: 'HON', iso2: 'HN', iso3: 'HND' },
	{ fivb: 'CRO', iso2: 'HR', iso3: 'HRV' },
	{ fivb: 'HAI', iso2: 'HT', iso3: 'HTI' },
	{ fivb: 'HUN', iso2: 'HU', iso3: 'HUN' },
	{ fivb: 'INA', iso2: 'ID', iso3: 'IDN' },
	{ fivb: 'IRL', iso2: 'IE', iso3: 'IRL' },
	{ fivb: 'ISR', iso2: 'IL', iso3: 'ISR' },
	{ fivb: 'IND', iso2: 'IN', iso3: 'IND' },
	{ fivb: 'IRQ', iso2: 'IQ', iso3: 'IRQ' },
	{ fivb: 'IRI', iso2: 'IR', iso3: 'IRN' },
	{ fivb: 'ISL', iso2: 'IS', iso3: 'ISL' },
	{ fivb: 'ITA', iso2: 'IT', iso3: 'ITA' },
	{ fivb: 'JAM', iso2: 'JM', iso3: 'JAM' },
	{ fivb: 'JOR', iso2: 'JO', iso3: 'JOR' },
	{ fivb: 'JPN', iso2: 'JP', iso3: 'JPN' },
	{ fivb: 'KEN', iso2: 'KE', iso3: 'KEN' },
	{ fivb: 'KGZ', iso2: 'KG', iso3: 'KGZ' },
	{ fivb: 'CAM', iso2: 'KH', iso3: 'KHM' },
	{ fivb: 'KIR', iso2: 'KI', iso3: 'KIR' },
	{ fivb: 'COM', iso2: 'KM', iso3: 'COM' },
	{ fivb: 'SKN', iso2: 'KN', iso3: 'KNA' },
	{ fivb: 'PRK', iso2: 'KP', iso3: 'PRK' },
	{ fivb: 'KOR', iso2: 'KR', iso3: 'KOR' },
	{ fivb: 'KUW', iso2: 'KW', iso3: 'KWT' },
	{ fivb: 'CAY', iso2: 'KY', iso3: 'CYM' },
	{ fivb: 'KAZ', iso2: 'KZ', iso3: 'KAZ' },
	{ fivb: 'LAO', iso2: 'LA', iso3: 'LAO' },
	{ fivb: 'LIB', iso2: 'LB', iso3: 'LBN' },
	{ fivb: 'LCA', iso2: 'LC', iso3: 'LCA' },
	{ fivb: 'LIE', iso2: 'LI', iso3: 'LIE' },
	{ fivb: 'SRI', iso2: 'LK', iso3: 'LKA' },
	{ fivb: 'LBR', iso2: 'LR', iso3: 'LBR' },
	{ fivb: 'LES', iso2: 'LS', iso3: 'LSO' },
	{ fivb: 'LTU', iso2: 'LT', iso3: 'LTU' },
	{ fivb: 'LUX', iso2: 'LU', iso3: 'LUX' },
	{ fivb: 'LAT', iso2: 'LV', iso3: 'LVA' },
	{ fivb: 'LBA', iso2: 'LY', iso3: 'LBY' },
	{ fivb: 'MAR', iso2: 'MA', iso3: 'MAR' },
	{ fivb: 'MON', iso2: 'MC', iso3: 'MCO' },
	{ fivb: 'MLD', iso2: 'MD', iso3: 'MDA' },
	{ fivb: 'MNE', iso2: 'ME', iso3: 'MNE' },
	{ fivb: 'MAD', iso2: 'MG', iso3: 'MDG' },
	{ fivb: 'MSH', iso2: 'MH', iso3: 'MHL' },
	{ fivb: 'MKD', iso2: 'MK', iso3: 'MKD' },
	{ fivb: 'MLI', iso2: 'ML', iso3: 'MLI' },
	{ fivb: 'MYA', iso2: 'MM', iso3: 'MMR' },
	{ fivb: 'MGL', iso2: 'MN', iso3: 'MNG' },
	{ fivb: 'MAC', iso2: 'MO', iso3: 'MAC' },
	{ fivb: 'NMI', iso2: 'MP', iso3: 'MNP' },
	{ fivb: 'MQE', iso2: 'MQ', iso3: 'MTQ' },
	{ fivb: 'MTN', iso2: 'MR', iso3: 'MRT' },
	{ fivb: 'MTS', iso2: 'MS', iso3: 'MSR' },
	{ fivb: 'MLT', iso2: 'MT', iso3: 'MLT' },
	{ fivb: 'MRI', iso2: 'MU', iso3: 'MUS' },
	{ fivb: 'MDV', iso2: 'MV', iso3: 'MDV' },
	{ fivb: 'MAW', iso2: 'MW', iso3: 'MWI' },
	{ fivb: 'MEX', iso2: 'MX', iso3: 'MEX' },
	{ fivb: 'MAS', iso2: 'MY', iso3: 'MYS' },
	{ fivb: 'MOZ', iso2: 'MZ', iso3: 'MOZ' },
	{ fivb: 'NAM', iso2: 'NA', iso3: 'NAM' },
	{ fivb: 'NIG', iso2: 'NE', iso3: 'NER' },
	{ fivb: 'NGR', iso2: 'NG', iso3: 'NGA' },
	{ fivb: 'NCA', iso2: 'NI', iso3: 'NIC' },
	{ fivb: 'NED', iso2: 'NL', iso3: 'NLD' },
	{ fivb: 'NOR', iso2: 'NO', iso3: 'NOR' },
	{ fivb: 'NEP', iso2: 'NP', iso3: 'NPL' },
	{ fivb: 'NRU', iso2: 'NR', iso3: 'NRU' },
	{ fivb: 'NIU', iso2: 'NU', iso3: 'NIU' },
	{ fivb: 'NZL', iso2: 'NZ', iso3: 'NZL' },
	{ fivb: 'OMA', iso2: 'OM', iso3: 'OMN' },
	{ fivb: 'PAN', iso2: 'PA', iso3: 'PAN' },
	{ fivb: 'PER', iso2: 'PE', iso3: 'PER' },
	{ fivb: 'PLY', iso2: 'PF', iso3: 'PYF' },
	{ fivb: 'PNG', iso2: 'PG', iso3: 'PNG' },
	{ fivb: 'PHI', iso2: 'PH', iso3: 'PHL' },
	{ fivb: 'PAK', iso2: 'PK', iso3: 'PAK' },
	{ fivb: 'POL', iso2: 'PL', iso3: 'POL' },
	{ fivb: 'PUR', iso2: 'PR', iso3: 'PRI' },
	{ fivb: 'PLE', iso2: 'PS', iso3: 'PSE' },
	{ fivb: 'POR', iso2: 'PT', iso3: 'PRT' },
	{ fivb: 'PAU', iso2: 'PW', iso3: 'PLW' },
	{ fivb: 'PAR', iso2: 'PY', iso3: 'PRY' },
	{ fivb: 'QAT', iso2: 'QA', iso3: 'QAT' },
	{ fivb: 'ROU', iso2: 'RO', iso3: 'ROU' },
	{ fivb: 'SRB', iso2: 'RS', iso3: 'SRB' },
	{ fivb: 'RUS', iso2: 'RU', iso3: 'RUS' },
	{ fivb: 'RWA', iso2: 'RW', iso3: 'RWA' },
	{ fivb: 'KSA', iso2: 'SA', iso3: 'SAU' },
	{ fivb: 'SOL', iso2: 'SB', iso3: 'SLB' },
	{ fivb: 'SEY', iso2: 'SC', iso3: 'SYC' },
	{ fivb: 'SUD', iso2: 'SD', iso3: 'SDN' },
	{ fivb: 'SWE', iso2: 'SE', iso3: 'SWE' },
	{ fivb: 'SIN', iso2: 'SG', iso3: 'SGP' },
	{ fivb: 'SLO', iso2: 'SI', iso3: 'SVN' },
	{ fivb: 'SVK', iso2: 'SK', iso3: 'SVK' },
	{ fivb: 'SLE', iso2: 'SL', iso3: 'SLE' },
	{ fivb: 'SMR', iso2: 'SM', iso3: 'SMR' },
	{ fivb: 'SEN', iso2: 'SN', iso3: 'SEN' },
	{ fivb: 'SOM', iso2: 'SO', iso3: 'SOM' },
	{ fivb: 'SUR', iso2: 'SR', iso3: 'SUR' },
	{ fivb: 'STP', iso2: 'ST', iso3: 'STP' },
	{ fivb: 'ESA', iso2: 'SV', iso3: 'SLV' },
	{ fivb: 'SYR', iso2: 'SY', iso3: 'SYR' },
	{ fivb: 'SWZ', iso2: 'SZ', iso3: 'SWZ' },
	{ fivb: 'CHA', iso2: 'TD', iso3: 'TCD' },
	{ fivb: 'TOG', iso2: 'TG', iso3: 'TGO' },
	{ fivb: 'THA', iso2: 'TH', iso3: 'THA' },
	{ fivb: 'TJK', iso2: 'TJ', iso3: 'TJK' },
	{ fivb: 'TLS', iso2: 'TL', iso3: 'TLS' },
	{ fivb: 'TKM', iso2: 'TM', iso3: 'TKM' },
	{ fivb: 'TUN', iso2: 'TN', iso3: 'TUN' },
	{ fivb: 'TGA', iso2: 'TO', iso3: 'TON' },
	{ fivb: 'TUR', iso2: 'TR', iso3: 'TUR' },
	{ fivb: 'TTO', iso2: 'TT', iso3: 'TTO' },
	{ fivb: 'TUV', iso2: 'TV', iso3: 'TUV' },
	{ fivb: 'TPE', iso2: 'TW', iso3: 'TWN' },
	{ fivb: 'TAN', iso2: 'TZ', iso3: 'TZA' },
	{ fivb: 'UKR', iso2: 'UA', iso3: 'UKR' },
	{ fivb: 'UGA', iso2: 'UG', iso3: 'UGA' },
	{ fivb: 'USA', iso2: 'US', iso3: 'USA' },
	{ fivb: 'URU', iso2: 'UY', iso3: 'URY' },
	{ fivb: 'UZB', iso2: 'UZ', iso3: 'UZB' },
	{ fivb: 'VIN', iso2: 'VC', iso3: 'VCT' },
	{ fivb: 'VEN', iso2: 'VE', iso3: 'VEN' },
	{ fivb: 'IVB', iso2: 'VG', iso3: 'VGB' },
	{ fivb: 'ISV', iso2: 'VI', iso3: 'VIR' },
	{ fivb: 'VIE', iso2: 'VN', iso3: 'VNM' },
	{ fivb: 'VAN', iso2: 'VU', iso3: 'VUT' },
	{ fivb: 'SAM', iso2: 'WS', iso3: 'WSM' },
	{ fivb: 'KOS', iso2: 'XK', iso3: 'KOS' },
	{ fivb: 'YEM', iso2: 'YE', iso3: 'YEM' },
	{ fivb: 'RSA', iso2: 'ZA', iso3: 'ZAF' },
	{ fivb: 'ZAM', iso2: 'ZM', iso3: 'ZMB' },
	{ fivb: 'ZIM', iso2: 'ZW', iso3: 'ZWE' },
	{ fivb: 'SSD', iso2: 'SS' },
];

export type CodeType = 'fivb' | 'iso2' | 'iso3';

export function convertCode(from: CodeType, to: CodeType, code: string | null): string | undefined {
	if (!code) {
		return;
	}

	const newCode = codes.find((c) => c[from] === code);

	if (!newCode) {
		console.error(`No code found for ${code}: ${from} => ${to}`);
		return '';
	}

	return newCode[to];
}
