export const DataType = {
	Address: 2,
	Airport: 3,
	Application: 4,
	BankAccount: 5,
	Coach: 16,
	Confederation: 17,
	Course: 18,
	Document: 20,
	SystemEmail: 21,
	SystemEmailText: 22,
	Event: 23,
	Federation: 24,
	Medium: 25,
	MedicalPerson: 26,
	MedicalPracticeAuthorization: 27,
	Person: 29,
	Player: 30,
	PlayersAgent: 31,
	Publication: 34,
	School: 35,
	User: 36,
	VolleyClub: 41,
	VolleyClubTeam: 42,
	VolleyTransfer: 52,
	VolleyTransferFeeInfo: 53,
	VolleyTransferPayment: 54,
	VisExportDefinition: 55,
	VolleySeason: 56,
	MediaOrganization: 57,
	MediaPerson: 58,
	EventAccreditation: 59,
	Referee: 61,
	EventReferee: 62,
	RefereeMatchesByYear: 63,
	DiaryEntry: 64,
	PlayerAccountingEntry: 65,
	PlayerInjury: 66,
	VolleyClubTeamPlayer: 69,
	VolleyHall: 70,
	ApplicationVersion: 73,
	Official: 74,
	EventOfficial: 75,
	FederationVolleyDivision: 79,
	FederationVolleyChampionship: 80,
	VolleyClubTeamChampionship: 81,
	FederationVolleySeason: 88,
	ConfederationVolleyTransferLevel: 89,
	ApplicationRequestStatistics: 92,
	Tag: 93,
	TagItem: 94,
	Article: 96,
	ArticleText: 97,
	PersonLanguage: 98,
	CourseAttender: 103,
	Tournament: 104,
	Template: 105,
	Phase: 106,
	TournamentTeam: 107,
	TournamentPlayer: 108,
	PhaseTeam: 109,
	Round: 110,
	Match: 111,
	TournamentRankingEntry: 112,
	RoundRankingEntry: 113,
	VerificationEntry: 114,
	LogEntry: 116,
	ChangesLogEntry: 117,
	UserRole: 118,
	ItemAccess: 119,
	Country: 120,
	UserRoleParameter: 121,
	DocumentTemplate: 122,
	VisTask: 123,
	VolleyTransferContract: 124,
	VolleyTransferCache: 125,
	NewUserTemplate: 126,
	VolleyTransferContractCache: 127,
	MergeLogEntry: 128,
	Passport: 129,
	Contact: 130,
	AmountWithCurrency: 131,
	Email: 132,
	PlayerSelections: 133,
	PlayerPerformance: 134,
	TournamentTeamOfficial: 135,
	Signature: 136,
	PlayerFederationChange: 137,
	TournamentParameters: 138,
	VolleyTransferPlayerBlockedDates: 139,
	BodyMeasurements: 140,
	ClothesSizes: 141,
	Language: 142,
	Parameter: 143,
	EventLogos: 144,
	Currency: 145,
	PlayerFederationDetermination: 146,
} as const;

export type DataType = (typeof DataType)[keyof typeof DataType];
