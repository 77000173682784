<script lang="ts" setup>
	import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
	import { useHead } from '@unhead/vue';
	import NotificationList from '~/app/common/components/notification-list.vue';
	import UpdateBanner from '~/app/core/components/update-banner.vue';

	useHead({
		titleTemplate: (title?: string) => (title ? `${title} | FIVB VIS WebManager` : 'FIVB VIS WebManager'),
	});
</script>

<template>
	<div class="font-sans antialiased">
		<component :is="$route.meta.layout || 'div'">
			<RouterView v-slot="{ Component }">
				<Transition name="fade" mode="out-in">
					<component :is="Component" />
				</Transition>
			</RouterView>
		</component>

		<NotificationList />
		<UpdateBanner />
		<VueQueryDevtools />
	</div>
</template>

<style lang="postcss">
	@import 'tailwindcss/base';
	@import 'tailwindcss/components';
	@import 'tailwindcss/utilities';

	@font-face {
		font-family: 'Atkinson-Hyperlegible';
		src: url('./assets/fonts/Atkinson-Hyperlegible/AtkinsonHyperlegible-Regular.woff2') format('woff2');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Atkinson-Hyperlegible';
		src: url('./assets/fonts/Atkinson-Hyperlegible/AtkinsonHyperlegible-Italic.woff2') format('woff2');
		font-weight: normal;
		font-style: italic;
		font-display: swap;
	}

	@font-face {
		font-family: 'Atkinson-Hyperlegible';
		src: url('./assets/fonts/Atkinson-Hyperlegible/AtkinsonHyperlegible-Bold.woff2') format('woff2');
		font-weight: bold;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Atkinson-Hyperlegible';
		src: url('./assets/fonts/Atkinson-Hyperlegible/AtkinsonHyperlegible-BoldItalic.woff2') format('woff2');
		font-weight: bold;
		font-style: italic;
		font-display: swap;
	}

	input:disabled,
	textarea:disabled,
	select:disabled {
		background-color: theme('colors.gray.50');
		color: theme('colors.gray.700');
		cursor: not-allowed;
	}

	/* Defaults */
	input {
		width: 100%;
	}

	.svg-icon {
		display: inline-block;
		fill: currentColor;
		stroke: none;
	}

	.svg-icon.only-stroke {
		fill: none;
		stroke: currentColor;
	}

	/* Page Transition */
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 200ms ease;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	/* Vendors Overwrite */
	.k-button,
	.k-input,
	.k-picker {
		font-family: theme('fontFamily.sans'), sans-serif !important;
	}

	.k-filterable.k-state-filtered {
		background-color: theme('colors.gray.100') !important;
		color: theme('colors.blue.500') !important;
	}

	.k-master-row.k-alt {
		background-color: theme('colors.gray.100') !important;
	}

	.k-picker-solid.k-dropdownlist {
		width: 100%;
	}

	select.k-picker {
		appearance: none;
	}

	.k-picker-solid {
		align-items: center;
		background-color: white !important;
		display: flex;
		font-size: theme('fontSize.sm') !important;

		.k-input-inner {
			text-overflow: initial !important;
		}

		.k-input-value-text {
			overflow: visible !important;
		}
	}

	.k-input,
	.k-list,
	.k-input-inner,
	.k-columnmenu-item,
	.k-columnmenu-item-content,
	.k-columnmenu-actions {
		font-size: theme('fontSize.xs') !important;
		font-family: theme('fontFamily.sans'), sans-serif !important;
	}

	.k-header,
	.k-grouping-header,
	.k-grid-pager,
	.k-grid-pager .k-pager-md {
		color: theme('colors.gray.500');
		font-family: theme('fontFamily.sans'), sans-serif !important;
		font-size: theme('fontSize.xs') !important;
		font-weight: theme('fontWeight.medium') !important;
		letter-spacing: theme('letterSpacing.wider') !important;
		line-height: theme('lineHeight.4') !important;
		padding: theme('padding.3') theme('padding.6') !important;
		text-transform: uppercase !important;
	}

	.k-grouping-header {
		background-color: white !important;
	}

	.k-grouping-header .k-chip {
		background-color: theme('colors.gray.100') !important;
		border-color: theme('colors.gray.300') !important;
		border-radius: theme('borderRadius.md') !important;
		color: theme('colors.gray.500') !important;
		font-family: theme('fontFamily.sans'), sans-serif !important;
		font-size: theme('fontSize.xs') !important;
		letter-spacing: theme('letterSpacing.wider') !important;
		line-height: theme('lineHeight.4') !important;
	}

	.k-pager-numbers .k-button-md {
		font-size: theme('fontSize.sm');
	}

	.k-pager-numbers .k-link.k-state-selected {
		color: theme('colors.blue.600') !important;
	}

	.k-grid .k-table-th.k-selected,
	.k-grid td.k-selected,
	.k-grid .k-table-td.k-selected,
	.k-grid .k-table-row.k-selected > td,
	.k-grid .k-table-row.k-selected > .k-table-td {
		background-color: theme('colors.blue.100') !important;
	}

	.k-list-filter {
		padding-inline: 1rem !important;
		padding-block: 0.25rem !important;
	}

	.k-columnmenu-actions .k-button {
		font-size: theme('fontSize.xs') !important;
	}

	.k-grid-column-menu .k-icon {
		font-size: inherit;
	}

	.k-master-row:hover,
	.k-master-row.k-alt:hover {
		background-color: theme('colors.gray.100') !important;
	}

	.k-master-row.k-alt {
		background-color: theme('colors.gray.50') !important;
	}

	.k-grid {
		border-radius: theme('borderRadius.lg');
		border: none;
		box-shadow: theme('boxShadow.DEFAULT');
		overflow: hidden;
	}

	.k-grid-header {
		background-color: theme('colors.gray.50') !important;
		user-select: none;
	}

	.k-grid tr {
		cursor: pointer;
	}

	.k-grid td {
		font-family: theme('fontFamily.sans'), sans-serif;
		font-size: theme('fontSize.sm') !important;
		overflow: hidden !important;
		text-overflow: ellipsis !important;
		white-space: nowrap !important;
	}

	.k-header.k-drag-clue {
		align-items: center !important;
		background-color: theme('colors.blue.600') !important;
		color: white !important;
		display: flex !important;
		font-family: theme('fontFamily.sans'), sans-serif !important;
		justify-content: center !important;
	}

	.k-grouping-dropclue::before {
		border-top-color: rgba(37, 99, 235, 1) !important;
	}

	.k-checkbox[type='checkbox'] {
		border-radius: theme('borderRadius.DEFAULT') !important;
	}

	.k-checkbox[type='checkbox']:checked {
		background-color: theme('colors.blue.600') !important;
		border-color: theme('colors.blue.600') !important;
	}
</style>
