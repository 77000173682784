<script lang="ts" setup>
	const props = defineProps<{
		src: string;
	}>();

	const image = ref<HTMLImageElement>();
	const state = ref('INIT');

	function loadImage() {
		image.value!.onload = () => {
			if (!image.value) {
				state.value = 'IMAGE_NOT_FOUND';
				return;
			}

			if (image.value!.height === 1) {
				state.value = 'IMAGE_NOT_FOUND';
				return;
			}

			state.value = 'IMAGE_LOADED';
		};

		image.value!.src = props.src;
	}

	onMounted(loadImage);
	watch(() => props.src, loadImage);
</script>

<template>
	<img v-show="state === 'IMAGE_LOADED'" v-bind="$attrs" ref="image" />
	<template v-if="state === 'IMAGE_NOT_FOUND'">
		<slot />
	</template>
</template>
