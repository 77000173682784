import type { Dayjs } from 'dayjs';
import type { ComputedRef, Ref } from 'vue';

export enum VOLLEY_CACHE_KEY {
	VOLLEY_CLUB_DIVISION_LIST = 'VOLLEY_CLUB_DIVISION_LIST',
}

export const queryKeyFactory = {
	root: ['clubs'] as const,
	lists: () => [...queryKeyFactory.root, 'list'] as const,
	list: (filters: ComputedRef<Record<string, unknown>>) => [...queryKeyFactory.lists(), filters] as const,
	details: () => [...queryKeyFactory.root, 'detail'] as const,
	detail: (id: Ref<number | null> | number) => [...queryKeyFactory.details(), id] as const,
	championshipAt: (id: Ref<number | null>, transferStartOn: Ref<Dayjs | null>) =>
		[...queryKeyFactory.root, 'championship-at', id, transferStartOn] as const,
};

export const queryKeys = queryKeyFactory;
export const volleyClubKeys = queryKeyFactory;

export const volleyClubTeamKeys = {
	root: ['volley-club-teams'] as const,
	lists: () => [...volleyClubTeamKeys.root, 'list'] as const,
	list: (filters: Record<string, unknown>) => [...volleyClubTeamKeys.lists(), filters] as const,
	details: () => [...volleyClubTeamKeys.root, 'detail'] as const,
	detail: (id: number) => [...volleyClubTeamKeys.details(), id] as const,
	playerList: (filters: Record<string, unknown>) => [...volleyClubTeamKeys.lists(), 'player-list', filters] as const,
};

export const seasonKeys = {
	root: ['volley-seasons'] as const,
	lists: () => [...seasonKeys.root, 'list'] as const,
	list: (filters: Record<string, unknown>) => [...seasonKeys.lists(), filters] as const,
	details: () => [...seasonKeys.root, 'detail'] as const,
	detail: (id: number) => [...seasonKeys.details(), id] as const,
	active: () => [...seasonKeys.root, 'active'] as const,
};

export const volleyPlayerKeys = {
	root: ['volley-players'] as const,
	lists: () => [...volleyPlayerKeys.root, 'list'] as const,
	list: (filters: Record<string, unknown>) => [...volleyPlayerKeys.lists(), filters] as const,
	details: () => [...volleyPlayerKeys.root, 'detail'] as const,
	detail: (id: number) => [...volleyPlayerKeys.details(), id] as const,
};

export const volleyTeamOfficialKeys = {
	root: ['volley-team-officials'] as const,
	lists: () => [...volleyTeamOfficialKeys.root, 'list'] as const,
	list: (filters: Record<string, unknown>) => [...volleyTeamOfficialKeys.lists(), filters] as const,
	details: () => [...volleyTeamOfficialKeys.root, 'detail'] as const,
	detail: (id: number) => [...volleyTeamOfficialKeys.details(), id] as const,
};

export const volleyTournamentKeys = {
	root: ['volley-tournaments'] as const,
	lists: () => [...volleyTournamentKeys.root, 'list'] as const,
	list: (filters: Record<string, unknown>) => [...volleyTournamentKeys.lists(), filters] as const,
	details: () => [...volleyTournamentKeys.root, 'detail'] as const,
	detail: (id: number) => [...volleyTournamentKeys.details(), id] as const,
	ranking: (id: number) => [...volleyTournamentKeys.detail(id), 'ranking'] as const,
};

export const volleyMatchKeys = {
	root: ['volley-matches'] as const,
	lists: () => [...volleyMatchKeys.root, 'list'] as const,
	list: (filters: Record<string, unknown>) => [...volleyMatchKeys.lists(), filters] as const,
	details: () => [...volleyMatchKeys.root, 'detail'] as const,
	detail: (id: number) => [...volleyMatchKeys.details(), id] as const,
};

export const volleyPoolKeys = {
	root: ['volley-pools'] as const,
	lists: () => [...volleyPoolKeys.root, 'list'] as const,
	list: (filters: Record<string, unknown>) => [...volleyPoolKeys.lists(), filters] as const,
	details: () => [...volleyPoolKeys.root, 'detail'] as const,
	detail: (id: number) => [...volleyPoolKeys.details(), id] as const,
};

export const volleyTeamKeys = {
	root: ['volley-teams'] as const,
	lists: () => [...volleyTeamKeys.root, 'list'] as const,
	list: (filters: Record<string, unknown>) => [...volleyTeamKeys.lists(), filters] as const,
	details: () => [...volleyTeamKeys.root, 'detail'] as const,
	detail: (id: number) => [...volleyTeamKeys.details(), id] as const,
};

export const eventRefereeKeys = {
	root: ['volley-event-referees'] as const,
	lists: () => [...eventRefereeKeys.root, 'list'] as const,
	list: (filters: Record<string, unknown>) => [...eventRefereeKeys.lists(), filters] as const,
	details: () => [...eventRefereeKeys.root, 'detail'] as const,
	detail: (id: number) => [...eventRefereeKeys.details(), id] as const,
};

export const eventOfficialKeys = {
	root: ['volley-event-officials'] as const,
	lists: () => [...eventOfficialKeys.root, 'list'] as const,
	list: (filters: Record<string, unknown>) => [...eventOfficialKeys.lists(), filters] as const,
	details: () => [...eventOfficialKeys.root, 'detail'] as const,
	detail: (id: number) => [...eventOfficialKeys.details(), id] as const,
};
