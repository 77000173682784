import type { RouteRecordRaw } from 'vue-router';
import TheShell from '@/ui/Layout/TheShell.vue';

export const routes: Array<RouteRecordRaw> = [
	{
		path: '/volley/transfers',
		component: () => import('@/app/transfers/views/TransferListView.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/volley/transfers/create',
		component: () => import('@/app/transfers/views/TransferCreateView.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/volley/transfers/:id(\\d+)',
		component: () => import('@/app/transfers/views/TransferView.vue'),
		meta: {
			layout: TheShell,
		},
	},
];
