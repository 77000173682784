<script lang="ts" setup>
	import SuccessNotification from '@/app/common/components/success-notification.vue';
	import { useNotification, type Notification } from '@/app/common/composables/use-notification';

	const props = defineProps<{
		notification: Notification;
		message: string;
	}>();

	const { remove } = useNotification();
	function close() {
		remove(props.notification.id);
	}
</script>

<template>
	<SuccessNotification @close="close">
		<p class="text-sm font-medium text-gray-900">{{ message }}</p>
	</SuccessNotification>
</template>
