import TheShell from '~/ui/Layout/TheShell.vue';
import type { RouteRecordRaw } from 'vue-router';

export const routes: Array<RouteRecordRaw> = [
	{
		path: '/players',
		component: () => import('~/app/players/views/player-list-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/players/:id(\\d+)',
		component: () => import('~/app/players/views/player-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/players/:id(\\d+)/eligibility-check',
		component: () => import('~/app/players/views/player-eligibility-check-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/players/:id(\\d+)/edit',
		component: () => import('~/app/players/views/player-edit-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/legal/foo-changes',
		component: () => import('~/app/players/views/foo-changes-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
];
