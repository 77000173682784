import type { RouteRecordRaw } from 'vue-router';

export const routes = [
	{
		path: '/privacy-policy',
		component: () => import('~/app/pages/views/privacy-policy-view.vue'),
		meta: {
			middleware: ['public'],
		},
	},
	{
		name: 'privacy-policy.subject',
		path: '/privacy-policy/subjects/:subject',
		component: () => import('~/app/pages/views/privacy-data-subject-view.vue'),
		meta: {
			middleware: ['public'],
		},
	},
] satisfies Array<RouteRecordRaw>;
