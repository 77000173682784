import TheShell from '~/ui/Layout/TheShell.vue';
import type { RouteRecordRaw } from 'vue-router';

export const routes = [
	{
		path: '/legal/determinations-of-federation',
		component: () => import('@/app/legal/views/determination-of-federation-list-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/legal/determinations-of-federation/:id(\\d+)',
		component: () => import('@/app/legal/views/determination-of-federation-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
] satisfies Array<RouteRecordRaw>;
