type Data = { no: number; deletedDT?: string | null } | { no: number; deletedAt: string | null };
type IndexedData<T extends Data> = Record<string, T>;

export function mergeData<T extends Data>(oldData: IndexedData<T> | undefined, newData: IndexedData<T>) {
	if (typeof oldData === 'undefined') {
		return newData;
	}

	if (!newData || Object.keys(newData).length <= 0) {
		return oldData;
	}

	const merging = structuredClone(oldData);

	for (const [no, item] of Object.entries(newData)) {
		if (('deletedDT' in item && item.deletedDT) || ('deletedAt' in item && item.deletedAt)) {
			delete merging[no];
			continue;
		}

		merging[no] = item;
	}

	return merging;
}
