import TheShell from '~/ui/Layout/TheShell.vue';
import type { RouteRecordRaw } from 'vue-router';

export const routes = [
	{
		path: '/development/empowerment',
		component: () => import('~/app/development/views/empowerment.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/development/empowerment/create',
		component: () => import('~/app/development/views/empowerment-create.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/development/empowerment/:id',
		component: () => import('~/app/development/views/empowerment-show-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/development/empowerment/:id/edit',
		component: () => import('~/app/development/views/empowerment-edit.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/development/empowerment/:id/communication',
		component: () => import('~/app/development/views/empowerment-communication.vue'),
		meta: {
			layout: TheShell,
		},
	},
] satisfies Array<RouteRecordRaw>;
