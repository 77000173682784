import type { InjectionKey, Ref } from 'vue';
import type { ClothingSize, Coach, MediaPerson, MedicalPerson, Official, Referee } from '~/app/person/schemas/person';
import type { DataType } from '~/domain/core/data-type';

/**
 * Must be provided at the root page component.
 */
export const personViewInjectKey = Symbol() as InjectionKey<{
	itemType: DataType;
	person: Ref<Referee | Official | MedicalPerson | MediaPerson | Coach | undefined>;
}>;

export const clothingSizesInjectKey = Symbol() as InjectionKey<{
	volleyClothesSizes: Ref<ClothingSize | undefined>;
	beachClothesSizes: Ref<ClothingSize | undefined>;
}>;

export const cacheKeyInjectKey = Symbol() as InjectionKey<{
	itemType: DataType;
	noItem: Ref<number | undefined>;
}>;
