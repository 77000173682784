<template>
	<span class="inline-flex items-center rounded-full font-semibold" :class="`${colorClasses} ${sizeClasses}`">
		<slot />
	</span>
</template>

<script lang="ts">
	export const BadgeColor = {
		Gray: 'gray',
		Red: 'red',
		Orange: 'orange',
		Yellow: 'yellow',
		Green: 'green',
		Teal: 'teal',
		Blue: 'blue',
		Indigo: 'indigo',
		Purple: 'purple',
		Pink: 'pink',
	};
</script>

<script lang="ts" setup>
	const props = defineProps({
		color: {
			type: String,
			required: false,
			default: 'gray',
		},

		size: {
			type: String,
			required: false,
			default: 'normal',
		},
	});

	const colorClasses = computed(() => {
		// PurgeCSS Whitelist
		// bg-teal-100 text-teal-100 bg-purple-100 text-purple-800 bg-pink-100 text-pink-800 bg-orange-100 text-orange-800 text-blue-800 bg-blue-100 bg-yellow-100 text-yellow-800 bg-green-100 text-green-800 bg-teal-100 text-teal-800 bg-gray-100 text-gray-800 bg-red-100 text-red-800 bg-indigo-100 text-indigo-800
		return `bg-${props.color}-100 text-${props.color}-800`;
	});

	const sizeClasses = computed(() => {
		if (props.size === 'xlarge') {
			return 'px-4 py-1 leading-5 text-base';
		}

		if (props.size === 'large') {
			return 'px-3 py-0.5 leading-5 text-sm';
		}

		return 'px-2.5 py-0.5 leading-4 text-xs';
	});
</script>
