<template>
	<Component
		v-bind="$attrs"
		:is="componentType"
		class="inline-flex items-center justify-center border font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
		:class="{
			// Sizes
			'h-8 rounded px-3 text-xs': size === 'xsmall',
			'h-10 rounded-md px-6 text-sm leading-4': size === 'small',
			'h-10 rounded-md px-8 text-sm': size === 'normal',
			'h-10 rounded-md px-8 text-base': size === 'large',
			'h-12 rounded-md px-10 text-base': size === 'xlarge',
		}"
		:type="type"
		:to="to"
	>
		<slot v-if="!loading" />
		<Loader v-else :color="loaderColor" />
	</Component>
</template>

<script lang="ts" setup>
	import type { PropType } from 'vue';
	import Loader from '@/ui/Common/Loader.vue';

	const props = defineProps({
		to: {
			type: String,
			required: false,
			default: undefined,
		},
		type: {
			type: String as PropType<'button' | 'submit' | 'reset'>,
			required: false,
			default: 'button',
			validator(value: string) {
				return ['button', 'submit', 'reset'].indexOf(value) !== -1;
			},
		},

		loading: {
			type: Boolean,
			required: false,
			default: false,
		},

		loaderColor: {
			type: String,
			required: false,
			default: 'white',
		},

		size: {
			type: String,
			required: false,
			default: 'normal',
			validator(value: string) {
				return ['xsmall', 'small', 'normal', 'large', 'xlarge'].indexOf(value) !== -1;
			},
		},
	});

	const componentType = props.to ? 'RouterLink' : 'button';
</script>
