import * as Sentry from '@sentry/vue';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/vue-query';
import { ZodError } from 'zod';
import ServerDownNotification from '~/app/common/components/notifications/server-down-notification.vue';
import ServerErrorNotification from '~/app/common/components/notifications/server-error-notification.vue';
import { useNotification } from '~/app/common/composables/use-notification';
import { HTTPError } from '~/infrastructure/http';
import router from '~/infrastructure/router';
import { HttpError } from '~/kernel/httpClient';
import { ValidationError } from 'myzod';

function handleFailureWithStatus(status: number, request: Request | null = null) {
	const notification = useNotification();

	switch (status) {
		case 401:
			if (request?.url.includes('/auth/login')) {
				return true;
			}

			window.location.reload();
			return true;
		case 404:
			void router.push('/404');
			return true;
		case 500:
			notification.add(ServerErrorNotification);
			return true;
		case 503:
		case 504:
			notification.add(ServerDownNotification);
			return true;
		default:
			return false;
	}
}

async function onError(error: any) {
	if (import.meta.env.DEV) {
		console.error(error);
	}

	if (error instanceof ZodError || error instanceof ValidationError) {
		Sentry.setExtra('Zod Error', JSON.stringify(error));
		Sentry.captureException(error);

		return;
	}

	if (error instanceof HTTPError || error instanceof HttpError) {
		const status = error instanceof HTTPError ? error.response.status : error.code;
		const request = error instanceof HTTPError ? error.request : null;
		const hasBeenHandled = handleFailureWithStatus(status, request);

		if (hasBeenHandled) {
			return;
		}
	}

	if (error instanceof HTTPError) {
		const contentType = error.response.headers.get('Content-Type');

		if (contentType === 'application/json') {
			const response = await error.response.clone().json();

			Sentry.addBreadcrumb({
				message: 'Request failed',
				category: 'http',
				level: 'info',
				data: {
					url: error.response.url,
					response,
				},
			});

			return;
		}
	}
}

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: (failureCount, error) => {
				if (error instanceof ZodError || error instanceof ValidationError) {
					return false;
				}

				if (error instanceof HTTPError || error instanceof HttpError) {
					const status = error instanceof HTTPError ? error.response.status : error.code;

					if ([400, 401, 404].includes(status)) {
						return false;
					}

					if ([500, 503, 504].includes(status) && failureCount <= 5) {
						return true;
					}
				}

				return false;
			},
			structuralSharing: false,
		},
	},

	mutationCache: new MutationCache({
		onError,
	}),

	queryCache: new QueryCache({
		onError,
	}),
});

// broadcastQueryClient({
// 	// @ts-expect-error - This is a known issue with the library
// 	queryClient,
// 	broadcastChannel: 'vis-web-manager',
// });
