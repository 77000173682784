import TheShell from '~/ui/Layout/TheShell.vue';
import type { RouteRecordRaw } from 'vue-router';

export const routes = [
	{
		path: '/media/articles',
		component: () => import('~/app/media/views/article-list-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
	{
		path: '/media/articles/:id/:languageCode',
		component: () => import('~/app/media/views/article-view.vue'),
		meta: {
			layout: TheShell,
		},
	},
] satisfies Array<RouteRecordRaw>;
