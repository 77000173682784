import type { Ref } from 'vue';

export const countryKeys = {
	root: ['countries'] as const,
	lists: () => [...countryKeys.root, 'list'] as const,
};

export const federationKeys = {
	root: ['federations'] as const,
	lists: () => [...federationKeys.root, 'list'] as const,
	list: (filters: Record<string, unknown>) => [...federationKeys.lists(), filters] as const,
	details: () => [...federationKeys.root, 'detail'] as const,
	detail: (id: number | Ref<number | null>) => [...federationKeys.details(), id] as const,
};

export const confederationKeys = {
	root: ['confederations'] as const,
	lists: () => [...confederationKeys.root, 'list'] as const,
	list: (filters: Record<string, unknown>) => [...confederationKeys.lists(), filters] as const,
	details: () => [...confederationKeys.root, 'detail'] as const,
	detail: (id: number) => [...confederationKeys.details(), id] as const,
};
