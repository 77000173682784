import type { Ref } from 'vue';
import type { DocumentListFilters } from '~/app/media/repositories/fetch-document-list';
import type { ArticleListFilters } from '~/domain/media/article-list-filter';

export const documentKeys = {
	root: ['document'] as const,
	lists: () => [...documentKeys.root, 'list'] as const,
	list: (filters: DocumentListFilters) => [...documentKeys.lists(), filters],
	details: () => [...documentKeys.root, 'detail'] as const,
	detail: (id: number) => [...documentKeys.details(), id],
} as const;

export const articleKeys = {
	root: ['article'] as const,
	lists: () => [...articleKeys.root, 'list'] as const,
	list: (filters: ArticleListFilters & { year?: Ref<number> }) => [...articleKeys.lists(), filters],
	details: () => [...articleKeys.root, 'detail'] as const,
	detail: (id: Ref<number>, languageCode: Ref<string>) => [...articleKeys.details(), { id, languageCode }],
} as const;
